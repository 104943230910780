.footer {
	width: 100%;
	background: white;
	/* display: block; */
 }

 .inner-footer {
	 width: 100%;
	 margin: auto;
	 /* padding: 30px 10px; */
	 display: flex;
	 flex-wrap: wrap;
	 box-sizing: border-box;
 }

.footer-items {
	/* width: 25%; */
	padding: 10px 20px;
	box-sizing: border-box;
	color: rgb(12, 12, 12);
}

.footer-items p {
	font-size: 16px;
	text-align: justify;
	line-height: 25px;
	color: rgb(14, 13, 13);
}

.footer-items h1 {
	color: rgb(2, 2, 2);
}

.border1 {
	height: 3px;
	width: 40px;
	background: #ff9800;
	color: #ff9800;
	background-color: #ff9800;
	border: 0px;
}

ul {
	list-style: none;
	color: rgb(12, 12, 12);
	font-size: 15px;
	letter-spacing: 0.5px;	
 }

ul a {
	text-decoration: none;
	outline: none;
	color: rgb(15, 15, 15);
	transition: 0.3s;
}

ul a:hover {
	color: #ff9800;
}

ul li {
	margin: 10px 0;
	height: 25px;
}

li i {
	margin-right: 20px;
}

.social-media {
	width: 100%;
	color: rgb(10, 10, 10);
	text-align: center;
	font-size: 20px;
}

.social-media a {
	text-decoration: none;
}

.social-media i {
	height: 25px;
	width: 25px;
	margin: 20px 10px;
	padding: 4px;
	color: rgb(10, 10, 10);
	transition: 0.5s;
}

.social-media i:hover {
	transform: scale(1.5);
}

.footer-bottom {
	padding: 10px;
	background: #eff1f1;
	color: rgb(7, 7, 7);
	font-size: 12px;
	text-align: center;
}

/* for tablet mode view */

@media screen and (max-width: 1275px) {
	.footer-items {
		width: 50%;
	}
}

/* for mobile screen view */

@media screen and (max-width: 660px) {
	.footer-items {
		width: 100%;
	}
}

.msbLogo{
	width: 240px !important;
	height: 120px;
	border-radius: 10px;
	 
	margin-left: -35px !important;
	margin-top: -15px !important;
	
}
.footerDescription{
	margin-top: -10px !important;
	margin-left: -60px !important;
}


