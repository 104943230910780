.afc-header--dark {
  position: inherit;
}

.afc-header--style-1 .afc-header__navbar__links > ul > li {
  line-height: 1;
}
.afc-header--style-1 .afc-header__navbar__links {
  width: inherit;
}
.msbLogo {
  width: 240px;
  height: 160px;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}
/* Hamburger menu styles for small screens */
@media screen and (max-width: 768px) {
  .afc-header__navbar__links {
    display: none; /* Hide the navigation menu */
  }

  .afc-header__navbar__icons {
    display: block; /* Display the hamburger menu icon */
  }

  /* Show the navigation menu when the hamburger icon is clicked */
  .afc-header__navbar__icons.open {
    display: none;
  }

  .afc-header__navbar__links.open {
    display: block;
  }
}

